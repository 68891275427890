<template>
  <div>
    <section
      class="
        grid grid-cols-3
        md:flex md:justify-center md:items-center
        lg:flex lg:justify-center lg:items-center
        h-screen
        w-screen
      "
    >
      <div
        class="relative col-span-2 bg-no-repeat bg-cover md:hidden lg:hidden"
        style="background-image: url(/images/Auth/login_bg.jpg)"
      >
        <div
          class="
            absolute
            bottom-16
            right-16
            w-3/4
            h-32
            bg-no-repeat bg-contain
            md:hidden
            lg:hidden
          "
          style="background-image: url(/images/Auth/login_bg_text.png)"
        ></div>
      </div>
      <div class="col-span-1 m-auto sm:mt-32 pb-3">
        <div
          class="
            py-3
            sm-ws:max-w-[433px]
            2xl:max-w-[390px]
            xl:max-w-[330px]
            my-0
            mx-auto
            sm:w-[85%] sm:m-auto
          "
        >
          <h2
            class="
              font-main font-bold
              text-3xl text-promy-primary
              md:text-xl
              lg:text-xl
              xl:text-xl
              login-headline
            "
          >
            Connexion
          </h2>
          <p
            class="
              w-4/5
              font-main font-normal
              text-base
              md:text-sm
              lg:text-sm
              xl:text-base
              text-promy-gray-250
              login-subheadline
            "
          >
            Entrez vos identifiants pour avoir accès à nos services
          </p>

          <PromyTabs :tabs="tabs_slot" class="mt-8">
            <template v-slot="{ current_tab }">
              <component :is="current_tab.component"></component>
            </template>
          </PromyTabs>

          <pro-button
            class="
              mt-5
              sm:w-2/5
              py-4
              px-40
              sm:px-36
              rounded-lg
              border-none
              h-14
              md:h-12
              sm:h-12
              w-full
              xl:text-base
            "
            btn="secondary"
            type="button"
            @click="$router.push({ name: 'Signup', query: $route.query })"
          >
            <span>Inscription</span>
          </pro-button>
          <div
            class="
              flex flex-col
              space-y-3
              xl:flex-row xl:justify-center xl:items-baseline xl:space-x-3
              md:flex-col md:space-y-3
              lg:flex-col lg:space-y-3
              sm:flex-row sm:justify-center sm:items-baseline
              mt-8
              text-promy-primary
              sm:space-x-5
            "
          >
            <div class="social-btn lg:text-sm">
              <social-login-button
                class="
                  w-full
                  lg:w-[90%] lg:mx-auto
                  sm:w-full
                  space-x-12
                  lg:space-x-10
                "
                btn="google"
                @click="() => social_login_handle('google')"
              />
            </div>
            <div class="social-btn lg:text-sm">
              <social-login-button
                class="
                  w-full
                  lg:w-[90%] lg:mx-auto
                  sm:w-full
                  space-x-12
                  lg:space-x-10
                "
                btn="facebook"
                @click="() => social_login_handle('facebook')"
              />
            </div>
            <div class="social-btn lg:text-sm">
              <social-login-button
                class="
                  w-full
                  lg:w-[90%] lg:mx-auto
                  sm:w-full
                  space-x-12
                  lg:space-x-10
                "
                btn="linkedin"
                @click="() => social_login_handle('linkedin')"
              />
            </div>
            <div class="social-btn lg:text-sm">
              <social-login-button
                class="
                  w-full
                  lg:w-[90%] lg:mx-auto
                  sm:w-full
                  space-x-12
                  lg:space-x-10
                "
                btn="apple"
                @click="() => social_login_handle('apple')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import AuthByPhoneNumber from './AuthByPhoneNumber.vue'
import AuthByEmail from './AuthByEmail.vue'

export default {
  components: {
    AuthByPhoneNumber,
    AuthByEmail,
  },
  data() {
    return {
      tabs_slot: [
        {
          component: 'AuthByPhoneNumber',
          label: 'Par numéro de Téléphone',
          is_active: true,
        },
        {
          component: 'AuthByEmail',
          label: 'Par email',
          is_active: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      cgu: 'auth/cgu',
      intendedLocation: 'auth/guestUserIntendedLocation',
      intendedAddress: 'auth/guestUserIntendedAddress',
    }),
  },
  methods: {
    ...mapActions({
      storeAddress: 'address/storeAddress',
    }),

    social_login_handle(service) {
      const appBase = process.env.VUE_APP_BASE_URL
      let query = ''
      Object.keys(this.$route.query).forEach((key, index) => {
        query =
          index == 0
            ? `?${key}=${this.$route.query[key]}`
            : `${query}&${key}=${this.$route.query[key]}`
      })
      axios.get(`${appBase}/sanctum/csrf-cookie`).then(() => {
        window.location.href = `${appBase}/grand-publics/social/redirect/${service}${query}`
      })
    },
  },
}
</script>
