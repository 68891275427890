<template>
  <div class="flex flex-col">
    <span
      v-if="errors.rate_limit"
      class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2"
      >{{ errors.rate_limit }}</span
    >
    <ValidationObserver
      v-if="!is_code_sended"
      tag="form"
      class="lg:mt-10 space-y-5 lg:text-sm"
      v-slot="{ invalid }"
    >
      <span
        v-if="errors.rate_limit"
        class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2"
        >{{ errors.rate_limit }}</span
      >
      <pro-input-tel
        label="Mobile"
        :rules="'required'"
        v-model="form.telephone_mobile"
        @is_valid="checkTelValidation"
      />

      <span
        v-if="errors.telephone_mobile && Array.isArray(errors.telephone_mobile)"
        class="rounded-lg w-full py-1 italic text-xs text-promy-actions-danger mt-2"
        >Ce numéro de téléphone n'existe pas</span
      >

      <pro-button
        class="w-full sm:w-2/5 !mt-8 py-4 px-40 sm:px-36 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base"
        btn="primary"
        @click.prevent="sendPhoneNumber"
        :disabled="is_tel_valid && !invalid ? false : true"
      >
        <span>Validez</span>
      </pro-button>
    </ValidationObserver>
    <ValidationObserver
      v-else
      tag="form"
      class="lg:mt-10 space-y-5 lg:text-sm"
      v-slot="{ invalid }"
    >
      <div class="flex flex-col">
        <span
          class="flex justify-between font-medium text-promy-gray-200 mb-2 sm:text-sm"
        >
          Veuillez insérer le code envoyé par sms :</span
        >
        <div class="flex items-top space-x-3">
          <pro-input
            label=""
            :placeholder="' '"
            :rules="'required'"
            v-model="form.code"
            class="flex-1 xl:w-full sm-ws:full md:w-full lg:w-full"
          />
          <a
            class="py-3 mt-[-4px] text-promy-green-350 cursor-pointer underline"
            style="height: fit-content"
            btn="primary"
            @click.prevent="sendPhoneNumber"
          >
            Renvoyez
          </a>
        </div>
      </div>

      <pro-button
        :disabled="invalid"
        class="w-full sm:w-2/5 !mt-8 py-4 px-40 sm:px-36 rounded-lg border-none h-14 md:h-12 sm:h-12 xl:text-base"
        btn="primary"
        @click.prevent="login"
      >
        <span>Connexion</span>
      </pro-button>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      errors: [],
      form: {
        is_register: false,
        telephone_mobile: null,
        code: null,
      },
      is_code_sended: false,
      classes: [
        'text-promy-green-350',
        'mr-2',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow',
        'text-xs',
        'bg-white',
        'mb-4',
      ],
      is_tel_valid: false,
    }
  },
  mounted() {
    this.form.telephone_mobile = this.$route.query.telephone_mobile
      ? this.$route.query.telephone_mobile
      : null
  },
  methods: {
    ...mapActions({
      storeAddress: 'address/storeAddress',
    }),
    sendPhoneNumber() {
      this.form.code = null
      this.$http
        .post('grand-publics/telephone/send', this.form)
        .then((res) => {
          this.is_code_sended = true
        })
        .catch((error) => {
          if ([422, 429].includes(error.response.status)) {
            this.errors = error.response.data.errors
          }
        })
    },
    async login() {
      try {
        await axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
        const response = await this.$http.post(
          'grand-publics/telephone/verify',
          this.form,
        )
        await this.$store.dispatch('auth/me')
        this.$store.commit('auth/SET_LOGGEDIN', true)
        this.$store.commit('auth/SET_USER', response.data)

        this.verifyUser(response.data)
      } catch (error) {
        if ([422, 429].includes(error.response.status)) {
          this.errors = error.response.data.errors
        }
      }
    },
    verifyUser(data) {
      let navbarRoutes = ['profile', 'historique', 'revelationStep1']

      if (data.email_verified_at || data.verified_telephone_at) {
        if (data.outsided_adress) {
          this.$router.push(`/sections-gu/parcelle/${data.outsided_adress}`)
          return
        }
        if (this.intendedAddress) {
          this.storeAddress({
            ...this.intendedAddress,
            prevent_parcelle_redirection: true,
          }).then(
            (resp) => {
              if (this.intendedLocation) {
                if (navbarRoutes.includes(this.intendedLocation)) {
                  this.$store.commit('address/SET_ADDRESS', null)
                  if (!navbarRoutes.includes(this.$route.name)) {
                    this.$router.push({ name: this.intendedLocation })
                  }
                  return
                }
                this.$router.push({
                  name: this.intendedLocation,
                  params: { id_address: resp.adresse.uuid },
                })
              } else {
                this.$router.push({
                  name: 'parcelle',
                  params: { id_address: resp.adresse.uuid },
                })
              }
              return
            },
            (error) => {
              this.$store.commit('address/SET_ADDRESS', null)
              this.$store.commit(
                'address/SET_ADDRESS_STORED_AND_RETRIEVED',
                false,
              )

              if (error.response.status === 422) {
                this.toast('Attention', error.response.data.message, 'warning')
              }

              this.$router.push({ name: 'historique' })
              return
            },
          )
        }
        if (
          navbarRoutes.includes(this.intendedLocation) &&
          !navbarRoutes.includes(this.$route.name)
        ) {
          this.$router.push({ name: this.intendedLocation })
          return
        }
        this.$router.push('/sections-gu/adresse')
      } else if (!data.email_verified_at) {
        this.$router.push({
          name: 'verify-email',
        })
      }
    },
    checkTelValidation(is_valid) {
      this.is_tel_valid = is_valid
    },
  },
}
</script>
