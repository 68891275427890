<template>
  <ValidationObserver
    tag="form"
    class="lg:mt-10 space-y-5 lg:text-sm"
    v-slot="{ invalid }"
  >
    <pro-input
      label="Email"
      :placeholder="'Email'"
      :rules="'required|email'"
      v-model="access.email"
      type="email"
      class="w-[96%] xl:w-full sm-ws:full md:w-full lg:w-full"
    />
    <pro-input
      label="Mot de passe"
      :placeholder="'Mot de passe'"
      :rules="'required'"
      v-model="access.mdp"
      :type="password_input_type"
      class="w-[96%] xl:w-full sm-ws:full md:w-full lg:w-full"
      withShowPasswordIcon
      @updateType="(type) => (password_input_type = type)"
    />
    <router-link
      class="w-max !mt-2.5 py-0 block border-b border-b-promy-green-300 text-promy-green-350"
      :to="{ name: 'passwordReset' }"
    >
      Mot de passe oublié ?
    </router-link>
    <pro-button
      :disabled="invalid"
      class="sm:w-2/5 !mt-8 py-4 px-40 sm:px-36 rounded-lg border-none h-14 md:h-12 sm:h-12 w-full xl:text-base"
      btn="primary"
      @click.prevent="login_handle"
    >
      <span>Connexion</span>
    </pro-button>
  </ValidationObserver>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      access: {
        email: this.$route.query.email,
        mdp: '',
      },
      password_input_type: 'password',
    }
  },
  computed: {
    ...mapGetters({
      intendedLocation: 'auth/guestUserIntendedLocation',
      intendedAddress: 'auth/guestUserIntendedAddress',
    }),
  },
  methods: {
    ...mapActions({
      storeAddress: 'address/storeAddress',
    }),
    async login_handle() {
      try {
        await axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`)
        const response = await this.$http.post('/grand-publics/login', {
          email: this.access.email,
          password: this.access.mdp,
          ...this.$route.query,
        })
        await this.$store.dispatch('auth/me')
        this.$store.commit('auth/SET_LOGGEDIN', true)
        this.$store.commit('auth/SET_USER', response.data)
        this.verifyUser(response.data)
      } catch (error) {
        let serverError = error.response.data.errors
        this.toast(
          'Connexion',
          serverError[Object.keys(serverError)[0]],
          'error',
        )
      }
    },
    verifyUser(data) {
      let navbarRoutes = ['profile', 'historique', 'revelationStep1']

      if (data.email_verified_at || data.verified_telephone_at) {
        if (data.outsided_adress) {
          this.$router.push(`/sections-gu/parcelle/${data.outsided_adress}`)
          return
        }
        if (this.intendedAddress) {
          this.storeAddress({
            ...this.intendedAddress,
            prevent_parcelle_redirection: true,
          }).then(
            (resp) => {
              if (this.intendedLocation) {
                if (navbarRoutes.includes(this.intendedLocation)) {
                  this.$store.commit('address/SET_ADDRESS', null)
                  if (!navbarRoutes.includes(this.$route.name)) {
                    this.$router.push({ name: this.intendedLocation })
                  }
                  return
                }
                this.$router.push({
                  name: this.intendedLocation,
                  params: { id_address: resp.adresse.uuid },
                })
              } else {
                this.$router.push({
                  name: 'parcelle',
                  params: { id_address: resp.adresse.uuid },
                })
              }
              return
            },
            (error) => {
              this.$store.commit('address/SET_ADDRESS', null)
              this.$store.commit(
                'address/SET_ADDRESS_STORED_AND_RETRIEVED',
                false,
              )

              if (error.response.status === 422) {
                this.toast('Attention', error.response.data.message, 'warning')
              }

              this.$router.push({ name: 'historique' })
              return
            },
          )
        }
        if (
          navbarRoutes.includes(this.intendedLocation) &&
          !navbarRoutes.includes(this.$route.name)
        ) {
          this.$router.push({ name: this.intendedLocation })
          return
        }
        this.$router.push('/sections-gu/adresse')
      } else if (!data.email_verified_at) {
        this.$router.push({
          name: 'verify-email',
        })
      }
    },
  },
}
</script>
